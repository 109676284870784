import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import { StyledLinkBox } from "./styles";

import skillsIcon from "static/svgs/artifact.svg";
import evidencesIcon from "static/svgs/badge-check.svg";
import dashboardIcon from "static/svgs/home.svg";
import activitiesIcon from "static/svgs/trophy.svg";

export type NavigationTag = "dashboard" | "activities" | "evidences" | "skills";
const Navigation = ({
  handleNavigate,
}: {
  handleNavigate: (id: NavigationTag) => void;
}) => {
  const theme = useTheme();
  const location = useLocation();

  const links: {
    tag: NavigationTag;
    icon: any;
  }[] = [
    { tag: "dashboard", icon: dashboardIcon },
    { tag: "activities", icon: activitiesIcon },
    { tag: "evidences", icon: evidencesIcon },
    { tag: "skills", icon: skillsIcon },
  ];

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.white,
        height: "100%",
        px: 4,
        pt: 3,
        textAlign: "center",
      }}
    >
      <Typography variant="h2">sff</Typography>
      <Stack mt={8} gap={3}>
        {links.map((link) => (
          <StyledLinkBox
            key={link.tag}
            isActive={link.tag === location.hash.slice(1)}
            onClick={() => handleNavigate(link.tag)}
          >
            <img src={link.icon} alt={`${link.tag}`} />
          </StyledLinkBox>
        ))}
      </Stack>
    </Box>
  );
};

export default Navigation;
