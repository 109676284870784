import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <Box
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={3}
    >
      <Typography>404 Not Found</Typography>
      <Link to="/">
        <Button variant="contained">Home</Button>
      </Link>
    </Box>
  );
};

export default NotFoundPage;
