import { Components } from "@mui/material";

const components: Components = {
  MuiButton: {
    styleOverrides: {
      root: {
        padding: "5px 25.5px",
      },
    },
  },
};

export default components;
