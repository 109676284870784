import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  schoolId: null,
  authorities: [],
  role: "",
  firstName: "",
  lastName: "",
};

export const STATE_KEY = "account";

const accountSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    login(state, action) {
      return action.payload;
    },

    logout() {
      return initialState;
    },

    accountUpdated(state, action) {
      const values = _.pick(action.payload, [
        "id",
        "email",
        "firstName",
        "lastName",
        "title",
        "receiveEmailDigest",
        "photoUrl",
        "schoolId",
        "schoolName",
      ]);
      return { ...state, ...values };
    },
  },
});

export const { login, logout, accountUpdated } = accountSlice.actions;
export default accountSlice.reducer;
