import {
  Alert,
  Box,
  CircularProgress,
  Grid2,
  Stack,
  Typography,
} from "@mui/material";
import { ReactNode, useEffect, useRef, useState } from "react";
import Navigation, { NavigationTag } from "./Navigation";
import ActivityCard from "./ActivityCard";
import NextActivity from "./NextActivity";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import { useSelector } from "react-redux";
import { getCurrentUser } from "lib/cognito";
import assessmentService, {
  GetModulesResponse,
  ModuleStatuses,
} from "services/assessmentService";

const BasePage = () => {
  const user = useSelector((state: any) => state.account);

  const navigate = useNavigate();
  const intersectionElementRef = useRef();

  const [loadingStatus, setLoadingStatus] =
    useState<"idle" | "pending" | "error" | "success">("pending");
  const [assessments, setAssessment] = useState<GetModulesResponse["data"]>([]);

  useEffect(() => {
    const fetchAssessments = async () => {
      setLoadingStatus("pending");
      // console.log('user=',user);
      try {
        const assessments = await assessmentService.getModulesWithStatus(
          user.id
        );
        setLoadingStatus("success");
        setAssessment(assessments);
      } catch (e) {
        setLoadingStatus("error");
        console.log("An error to fetch assessments: ", e);
      }
    };

    fetchAssessments();
  }, [user.id]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            navigate(`/#${entry.target.id}`);
          }
        });
      },
      { rootMargin: "0px 0px -100%", root: intersectionElementRef.current }
    );

    Array.from(document.querySelectorAll("div[id]"))
      .filter((section) => section.id !== "root")
      .forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, [navigate]);

  const handleNavigate = (id: NavigationTag) => {
    const target = document.getElementById(id);
    // Instant scrolling is a compromise between intersection observer and link navigation.
    // If set to ‘smooth’, the intersection watcher will be triggered and the url will flicker
    target?.scrollIntoView({ behavior: "auto" });
  };

  const loadingStatusUI: Record<typeof loadingStatus, ReactNode> = {
    error: (
      <Alert severity="error">
        An error occurred while fetching assessments.
      </Alert>
    ),
    idle: null,
    pending: <CircularProgress />,
    success: null,
  };

  return (
    <Stack
      direction="row"
      flexWrap="nowrap"
      overflow="hidden"
      height="100%"
      sx={{ backgroundColor: "rgba(254, 253, 251, 1)" }}
    >
      <Box sx={{ height: "100%", width: "fit-content", flex: 0 }}>
        <Navigation handleNavigate={handleNavigate} />
      </Box>
      <Box flex={1} height="100%" display="flex" flexDirection="column">
        <Header user={user} />
        <Box
          ref={intersectionElementRef}
          sx={{
            overflow: "auto",
            height: "100%",
            position: "relative",
          }}
        >
          {/* Activities section */}
          <Box
            px={5}
            pt={10}
            id="activities"
            sx={{ minHeight: "100vh", maxWidth: 877 }}
          >
            {loadingStatusUI[loadingStatus]=='success' || (
              <>
                <Typography>Continue on your Next Activity</Typography>
                <Box mt={3}>
                  <NextActivity module={assessments.filter(item => (!item.status || (item.status as any).status !=ModuleStatuses.COMPLETED ))[0]} />
                </Box>
                <Box mt={7}>
                  <Typography>All Activities</Typography>
                  <Grid2 container spacing={2} columns={2} sx={{ mt: 2 }}>
                    {assessments.map((activity) => (
                      <ActivityCard key={activity.id} module={activity} />
                    ))}
                  </Grid2>
                </Box>
              </>
            )}
          </Box>
          {/* Evidences section */}
          <Box id="evidences" mt={5} px={5}>
            <Box sx={{ backgroundColor: "aliceblue", minHeight: "100vh" }}>
              <Alert severity="info">
                Evidences section is under development
              </Alert>
            </Box>
          </Box>
          {/* Skills section */}
          <Box id="skills" mt={5} px={5}>
            <Box sx={{ backgroundColor: "aliceblue", minHeight: "100vh" }}>
              <Alert severity="info">Skills section is under development</Alert>
            </Box>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

export default BasePage;
