import BaseService from "services/baseService";
import FetchService from "services/fetchService";
import modules from "mock/data.json";
import mockModuleStatus from "mock/moduleStatus.json";

export enum ModuleStatuses {
  "IN_PROGRESS" = "IN_PROGRESS",
  "COMPLETED" = "COMPLETED",
}
export enum ActivityStatuses {
  "IN_PROGRESS" = "IN_PROGRESS",
  "COMPLETED" = "COMPLETED",
}
export type ModuleActivity = {
  activityId: string;
  sequence: number;
  name: string;
  description: string;
};

export type ModuleSkill = {
  skillId: string;
  activities: string[];
  subSkillIds: string[];
};

export type Module = {
  id: string;
  name: string;
  status: ModuleStatuses;
  shortDescription: string;
  launchURL: string;
  duration: number;
  requiresVideo: boolean;
  requiresAudio: boolean;
  sequence: number;
  skills: ModuleSkill[];
  activities: ModuleActivity[];
};

export type GetModulesResponse = {
  awsRequestId: string;
  userId: string;
  data: Module[];
};
class AssessmentService extends BaseService {
  servicePath = "assessment-modules";

  getModules(userId: string) {
    // return modules["data"] as Module[];
    return FetchService.get<GetModulesResponse["data"]>({
      url: this.getUrl(),
      data: { userId },
      needAuth: true,
    });
  }

  getStatus(userId: string) {
    // return mockModuleStatus["data"]["data"] as any[];
    return FetchService.get<any>({
      url: this.getUrl("status"),
      data: { userId },
    });
  }

  async getModulesWithStatus(userId: string) {
    let modules = await this.getModules(userId);
    let statuses: any = await this.getStatus(userId);

    console.log('modules=',modules);
    console.log('statuses=',statuses);
    let statusesArr = statuses || [];

    let statusMap: Map<string, any> = new Map();
    for (let i = 0; i < statusesArr.length; i++) {
      const element = statusesArr[i];
      statusMap.set(element.id, element);
    }

    (modules || []).map((module: any) => {
      let key;
      if (statusMap.has(module.id)) {
        key = module.id;
      } else if (statusMap.size > 0) {
        let result = statusMap.entries().next();
        key = result.value?.[0];
      }
      if (key) {
        module.status = statusMap.get(key);
        statusMap.delete(key);
      }
    });

    // (modules[0].status as any).status = 'COMPLETED';
    // (modules[1].status as any) = undefined;
    console.log("modules==", modules);
    return modules;
  }
}

const assessmentService = new AssessmentService();
export default assessmentService;
