import { Box, styled } from "@mui/material";

export const StyledLinkBox = styled(Box, {
  shouldForwardProp(propName) {
    return propName !== "isActive";
  },
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  ":hover": { backgroundColor: "rgba(238, 242, 253, 1)" },
  backgroundColor: isActive ? "rgba(238, 242, 253, 1)" : "none",
  borderRadius: 4,
  cursor: "pointer",
}));
