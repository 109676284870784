import BaseService from "./baseService";
import FetchService from "./fetchService";
import {
  SchoolAcademicYearType,
  SchoolFeature,
  SchoolSendingType,
  UserRole,
  UserStatus,
} from "./types";
import { removeDataFromLocalStorage } from "../utils/localStorage";

import * as cognito from "../lib/cognito";

export type AuthUser = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: UserRole;
  title: string | null;
  authorities: UserRole[];
  photoUrl: string | null;
  status: UserStatus;
  expirationDate: string | null;
  schoolId: number | null;
  schoolName: string | null;
  schoolAcademicYearType: SchoolAcademicYearType | null;
  schoolFeatures: SchoolFeature[];
  sendingType: SchoolSendingType | null;
  schools: {
    id: number;
    name: string | null;
    academicYearType: SchoolAcademicYearType | null;
    features: SchoolFeature[];
    sendingType: SchoolSendingType;
  }[];
};

class AuthService extends BaseService {
  static account: AuthUser | undefined;

  servicePath = "auth";

  async lookupEmail({ email }: { email: string }) {
    if (email.toLowerCase().endsWith("@ets.org")) {
      //TODO: To ignore username check for cognito
      return true;
    }

    return FetchService.post<true>({
      url: this.getUrl("lookup"),
      data: { email: email },
      needAuth: false,
    });
  }

  async lookupInvite({ inviteCode }: { inviteCode: string }) {
    return FetchService.post<{
      email: string;
      firstName: string;
      lastName: string;
    }>({
      url: this.getUrl("invite/lookup"),
      data: { inviteCode },
      needAuth: false,
    });
  }

  async acceptInvite({
    inviteCode,
    firstName,
    lastName,
    password,
  }: {
    inviteCode: string;
    firstName: string;
    lastName: string;
    password: string;
  }) {
    return FetchService.post<true>({
      url: this.getUrl("invite/accept"),
      data: { inviteCode, firstName, lastName, password },
      needAuth: false,
    });
  }

  resendInvite(email: string) {
    return FetchService.post<true>({
      url: this.getUrl("invite/resend"),
      data: { email },
    });
  }

  restorePassword(email: string) {
    return FetchService.post<true>({
      url: this.getUrl("recovery/initiate"),
      data: { email },
    });
  }

  passwordReset(data: { password: string; recoveryCode: string }) {
    return FetchService.post<true>({
      url: this.getUrl("recovery/reset"),
      data,
    });
  }

  cancelPasswordReset(data: { recoveryCode: string }) {
    return FetchService.post<true>({
      url: this.getUrl("recovery/cancel"),
      data,
    });
  }

  async loggedInWithCognitoToken({
    cognitoToken
  }: {
    cognitoToken: string;
  }) {
    try {
      console.log("resp access=", cognitoToken);
      this.setCognitoAuthToken(cognitoToken);

      const reqData = {
        url: this.getUrl("login/aws-cognito"),
        data: { token: cognitoToken },
        needAuth: false,
      };

      removeDataFromLocalStorage("loggedAs"); // remove "loggedAs" just in case
      let loginResp: any = await FetchService.post<string>(reqData);
      console.log("loginResp=", loginResp);
      this.setAuthToken(loginResp.access);
      return loginResp;
      // return await this.loadAccount();
    } catch (err) {
      console.log("error=", err);
      throw err;
    }
  }

  async loginWithCognito({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) {
    try {
      const resp: any = await cognito.signInWithEmail(email, password);
      console.log("resp=", resp);
      const token = resp?.accessToken?.jwtToken;
      console.log("resp access=", token);
      this.setCognitoAuthToken(token);

      const reqData = {
        url: this.getUrl("login/aws-cognito"),
        data: { token },
        needAuth: false,
      };

      removeDataFromLocalStorage("loggedAs"); // remove "loggedAs" just in case
      let loginResp: any = await FetchService.post<string>(reqData);
      console.log("loginResp=", loginResp);
      this.setAuthToken(loginResp.access);
      return loginResp;
      // return await this.loadAccount();
    } catch (err) {
      console.log("error=", err);
      throw err;
    }
  }

  async login({ email, password }: { email: string; password: string }) {
    if (email.toLowerCase().endsWith("@ets.org")) {
      //TODO:
      return await this.loginWithCognito({ email, password });
    }

    const reqData = {
      url: this.getUrl("login"),
      data: { email, password },
      needAuth: true,
    };

    removeDataFromLocalStorage("loggedAs"); // remove "loggedAs" just in case
    let loginResp: any = await FetchService.post<string>(reqData);
    console.log("loginResp=", loginResp);
    this.setAuthToken(loginResp.access);
    return loginResp;

    // return await this.loadAccount();
  }

  async loadAccount() {
    AuthService.account = await FetchService.get<AuthUser>({
      url: this.getBaseUrl() + "/account",
    });
    return AuthService.account;

    // return { id: 1, firstName: "Dmitry", lastName: "Karmanov" };
  }

  getAccount() {
    return AuthService.account;
  }

  hasAnyAuthority(authorities: UserRole[]) {
    // no authorities required <=> access granted
    if (!authorities) return true;
    if (Array.isArray(authorities) && authorities.length === 0) return true;

    const account = this.getAccount();

    if (
      !account ||
      !Array.isArray(account.authorities) ||
      !account.authorities.length
    ) {
      return false;
    }

    return authorities.some((a) => account.authorities.includes(a));
  }

  getStorage() {
    // TODO: implement remember me
    const rememberMe = true;
    if (rememberMe) {
      return localStorage;
    }
    return sessionStorage;
  }

  getAuthToken() {
    return this.getStorage().getItem("authToken");
  }

  setAuthToken(token: string) {
    this.getStorage().setItem("authToken", token);
    localStorage.setItem("login-event", `logout${Math.random()}`);
  }

  getCognitoAuthToken() {
    return this.getStorage().getItem("cognitoAuthToken");
  }
  setCognitoAuthToken(token: string) {
    this.getStorage().setItem("cognitoAuthToken", token);
  }

  removeAuthToken() {
    sessionStorage.removeItem("authToken");
    sessionStorage.removeItem("cognitoAuthToken");
    removeDataFromLocalStorage("authToken");
    removeDataFromLocalStorage("cognitoAuthToken");
    localStorage.setItem("logout-event", `logout${Math.random()}`);
  }

  getAuthHeader() {
    return { headers: { Authorization: this.getAuthToken() } };
  }

  logout() {
    FetchService.get({ url: this.getUrl("logout") });
    removeDataFromLocalStorage("loggedAs");
    // clear filters
    removeDataFromLocalStorage("filters");
    // inform system of logout event
    this.removeAuthToken();

    AuthService.account = undefined;
  }

  cleverSignIn(code: string) {
    return FetchService.post<{
      token: string;
      cleverToken: string;
      user: unknown;
    }>({
      url: this.getUrl("login/clever"),
      data: { code },
    });
  }

  googleSignIn(token: string) {
    return FetchService.post<string>({
      url: this.getUrl("login/google"),
      data: { token },
    });
  }

  lmsSignIn(token: string) {
    return FetchService.post<string>({
      url: this.getUrl("login/lti"),
      data: { token },
    });
  }

  loginAs(userId: number) {
    return FetchService.get<string>({
      url: this.getUrl("loginAs"),
      data: { userId },
    });
  }

  logoutAs() {
    return FetchService.get<string>({ url: this.getUrl("logoutAs") });
  }
}

const authService = new AuthService();
export default authService;
