import { blue } from "@mui/material/colors";
import { PaletteOptions } from "@mui/material/styles";

export const palette: PaletteOptions = {
  common: {
    black: "#000",
    white: "#fff",
  },
  primary: {
    main: "#157CB9",
    light: "#28A9E1",
    dark: "#174777",
    contrastText: "#fff",
  },
  secondary: {
    main: blue[600],
    light: blue[400],
    dark: blue[900],
    contrastText: "#000",
  },
  error: {
    main: "#e00",
    light: "#F04E30",
    dark: "#992123",
    contrastText: "#fff",
  },
  success: {
    main: "#4caf50",
    light: "#80e27e",
    dark: "#087f23",
    contrastText: "#000",
  },
  warning: {
    main: "#ff9800",
    light: "#bb8033",
    dark: "#8F5100",
    contrastText: "#000",
  },
  grey: {
    50: "#fafafa",
    100: "#f5f5f5",
    200: "#eeeeee",
    300: "#e0e0e0",
    400: "#bdbdbd",
    500: "#9e9e9e",
    600: "#757575",
    700: "#616161",
    800: "#424242",
    900: "#212121",
    A100: "#d5d5d5",
    A200: "#aaaaaa",
    A400: "#303030",
    A700: "#616161",
  },
  text: {
    primary: "#000",
    secondary: "#757575",
    disabled: "rgba(0,0,0,0.54)",
  },
  background: {
    default: "#f9f9fc",
    paper: "#fff",
  },
  action: {
    disabled: "rgba(0, 0, 0, 0.36)",
    disabledBackground: "#F5F5F5",
  },
};
