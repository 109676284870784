import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import activityCard from "static/activityCard.png";
import microphoneIcon from "static/svgs/microphone.svg";
import headphonesIcon from "static/svgs/headphones.svg";
import lockIcon from "static/svgs/lockicon.svg";
import { Module, ModuleStatuses } from "services/assessmentService";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

type ActivityCardProps = {
  module: Module;
  isNextActivity?: boolean;
};
const ActivityCard = ({
  module,
  isNextActivity = false,
}: ActivityCardProps) => {
  const theme = useTheme();

  const moduleStatus = (module?.status as any)?.status;
  const isLocked = (!isNextActivity && !moduleStatus);

  const getLaunchURL = (launchUrl: string) => {
    try {
      if(moduleStatus === ModuleStatuses.COMPLETED && launchUrl && launchUrl.includes('?to=')) {
        const toIndex = launchUrl.indexOf('?to=');
        const toStrB64 = launchUrl.substring(toIndex + 4);
        const decodedString = atob(toStrB64);
        const newRedirectTo = decodedString.replace('/intro', '/insights/skills-report');
        const encodedString = btoa(newRedirectTo); 
        console.log(`toStrB64=${toStrB64}, decodedString=${decodedString},newUrl=${newRedirectTo},encodedString=${encodedString}`);
        const returnUrl = launchUrl.substring(0, toIndex + 4) + encodedString;
        return returnUrl;
      }
      else {
        return launchUrl;
      }
    }
    catch(error) {
      return launchUrl;
    }
  }

  

  return (
    <Box
      sx={{
        borderRadius: 6,
        position: "relative",
        width: isNextActivity ? 514 : 408,
        backgroundImage: `url("${activityCard}")`,
        backgroundSize: "cover",
        height: isNextActivity ? 207 : 297,
        opacity: isLocked ? 0.3 : 1
      }}
    >
      {
        <>
        {isLocked && 
          <Box
          sx={{
            position: "absolute",
            top: 20,
            right: 20,
            width: 40,
            height: 40,                    
            backgroundColor: 'white',
            borderRadius: 20,
            boxShadow: "0 0 2px 1px black",
            textAlign: "center",
            alignContent: "center"
          }}
        >
          <img src={lockIcon} alt=""
          width={"50%"} 
           />
        </Box>
        }
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            transform: isNextActivity ? "translateY(50%)" : undefined,
            width: "100%",
            padding: isNextActivity ? 0 : 5,
          }}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: theme.palette.common.white,
              borderRadius: 4,
              py: 6,
              px: 9,
            }}
          >
            {isNextActivity && (
              <Stack direction="row" alignItems="center" spacing={3}>
                <Typography
                  sx={{ fontWeight: 600, color: theme.palette.grey[500] }}
                >
                  {module.duration / 60} min
                </Typography>
                <Stack ml={1} direction="row">
                  {module.requiresVideo && <img src={headphonesIcon} alt="" />}
                  {module.requiresAudio && <img src={microphoneIcon} alt="" />}
                </Stack>
              </Stack>
            )}

            <Typography
              variant={isNextActivity ? "h2" : "h3"}
              sx={{
                fontWeight: 500,
              }}
            >
              {module.name}
            </Typography>

            {!isNextActivity && (
              <>
                {moduleStatus === ModuleStatuses.COMPLETED && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 40,
                      backgroundColor: "rgba(0, 82, 210, 1)",
                      color: theme.palette.common.white,
                      px: 3,
                      py: 1.5,
                      borderRadius: 20,
                      transform: "translateY(-50%)",
                      fontWeight: 600,
                    }}
                  >
                    <CheckCircleOutlineIcon fontSize="small" />
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      Completed
                    </Typography>
                  </Stack>
                )}
                {moduleStatus && (
                  <Button
                    href={getLaunchURL(module.launchURL)} target="launch_assessment"
                    variant="contained"
                    sx={{
                      backgroundColor: theme.palette.common.black,
                      mt: 2,
                      borderRadius: "50px",
                    }}
                  >
                    <Typography
                      sx={{ color: theme.palette.common.white, fontWeight: 600 }}
                    >
                      {!moduleStatus ? 'Start Activity' : moduleStatus == ModuleStatuses.IN_PROGRESS? 'Continue' : 'See Results'}
                    </Typography>
                  </Button>
                )}
              </>
            )}
          </Box>
        </Box>
        </>
      }
    </Box>
  );
};

export default ActivityCard;
