import { TypographyVariantsOptions } from "@mui/material/styles";

export const typography: TypographyVariantsOptions = {
  fontFamily: ["GeneralSans", "sans-serif"].join(","),
  fontFamilyCondensed: [].join(","),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  h1: {
    fontSize: "3rem",
    fontWeight: 600,
    lineHeight: 1.43,
  },
  h2: {
    fontSize: "2.25rem",
    fontWeight: 400,
    lineHeight: 1.43,
  },
  h3: {
    fontSize: "2rem",
    fontWeight: 500,
    lineHeight: 1.43,
  },
  h4: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  h5: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  h6: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  body1: {
    fontSize: "1rem",
  },
  body2: {
    fontSize: ".875rem",
  },
  caption: {
    lineHeight: 1.43,
  },
  button: {
    textTransform: "none",
  },
};
