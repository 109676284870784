import ActivityCard from "../ActivityCard";
import { Box, Button, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { Module, ModuleStatuses } from "services/assessmentService";
import primaryLarge from "static/svgs/primaryLarge.svg";
import { ReactComponent as VectorLogo } from "static/svgs/vector.svg";

type NextActivityProps = {
  module: Module | null;
};
const NextActivity = ({ module }: NextActivityProps) => {
  const theme = useTheme();

  if (!module) return null;

  const moduleStatus = (module?.status as any)?.status;

  return (
    <Box
      position="relative"
      height="325px"
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
    >
      <Box sx={{ position: "absolute", left: 36, top: 0, zIndex: 1 }}>
        <ActivityCard module={module} isNextActivity />
      </Box>
      <Box
        sx={{
          position: "absolute",
          flex: 1,
          top: 64,
          borderRadius: 6,
          height: "80%",
          width: "100%",
          backgroundColor: "rgba(77, 211, 254, 1)",
          overflow: "hidden",
        }}
      >
        <VectorLogo
          fill="rgba(145, 228, 255, 0.67)"
          style={{ position: "absolute", right: 0, width: "100%" }}
        />
        <Box sx={{ position: "absolute", right: 40, top: 36, maxWidth: 200 }}>
          <Stack direction="row" gap={2.5} flexWrap="wrap">
            {module.skills.map((skill) => (
              <Typography
                key={skill.skillId}
                component="span"
                variant="body2"
                sx={{
                  backgroundColor: theme.palette.common.white,
                  borderRadius: "50px",
                  py: 1.5,
                  px: 3,
                }}
              >
                {skill.skillId
                  .slice(0, 1)
                  .toUpperCase()
                  .concat(skill.skillId.slice(1))}
              </Typography>
            ))}
          </Stack>
        </Box>
        {/* <IconButton
          sx={{ position: "absolute", right: 25, bottom: 25 }}
          href={module.launchURL}
          target="_blank"
        > 
          <img src={primaryLarge} alt="" />
        </IconButton> */}

        <Button 
            href={module.launchURL} target="launch_assessment"
            variant="contained"
            sx={{ 
              backgroundColor: theme.palette.common.black,
              borderRadius: "50px",
              position: "absolute", right: 25, bottom: 25
            }}
          >
            <Typography
              sx={{ color: theme.palette.common.white, fontWeight: 600, fontSize: 20, mr:2 }}
            >
              {!moduleStatus ? 'Begin' : moduleStatus == ModuleStatuses.IN_PROGRESS? 'Continue' : 'See Results'}
            </Typography>
            <img src={primaryLarge} alt="" height={40} />
          </Button>
      </Box>
    </Box>
  );
};

export default NextActivity;
