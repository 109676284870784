import { Box, Stack, Typography, useTheme } from "@mui/material";
import UserMenu from "../UserMenu";
import { useLocation } from "react-router-dom";
import { ReactComponent as VectorLogo } from "static/svgs/vector.svg";
import { AuthUser } from "services/authService";
import { NavigationTag } from "../Navigation";

const Header = ({ user }: { user: AuthUser }) => {
  const theme = useTheme();
  const location = useLocation();

  const headerTitles: Record<NavigationTag, string> = {
    dashboard: "Dashboard",
    activities: "Activities",
    evidences: "Evidences",
    skills: "Skills",
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          paddingTop: 13.5,
          paddingLeft: 10,
          paddingRight: 8,
          paddingBottom: 32,
          width: "100%",
          backgroundColor: "rgba(0, 82, 210, 1)",
          borderTopLeftRadius: 24,
        }}
      >
        <VectorLogo
          style={{
            fill: "rgba(20, 96, 214, 1)",
            position: "absolute",
            right: 0,
            top: 0,
            width: "100%",
          }}
        />
        <Stack
          direction="row"
          flexWrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mixBlendMode: "lighten" }}
        >
          <Typography variant="h1" sx={{ color: theme.palette.common.white }}>
            {headerTitles[location.hash.slice(1) as keyof typeof headerTitles]}
          </Typography>
          <UserMenu user={user} />
        </Stack>
      </Box>
    </>
  );
};

export default Header;
