import { Avatar as MuiAvatar, useTheme } from "@mui/material";
import React from "react";

type AvatarProps = {
  firstName: string;
  lastName: string;
};
const Avatar = ({ firstName, lastName }: AvatarProps) => {
  const theme = useTheme();

  return (
    <MuiAvatar
      sx={{
        borderRadius: "50%",
        backgroundColor: "white",
        color: theme.palette.grey[500],
      }}
    >
      {firstName.slice(0, 1).toUpperCase() + lastName.slice(0, 1).toUpperCase()}
    </MuiAvatar>
  );
};

export default Avatar;
