import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { id } = useSelector((state: any) => state.account);
  const isLoggedIn = !!id;

  return isLoggedIn ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
